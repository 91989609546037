// @ts-nocheck
import { useNavigate } from "react-router-dom";
import AuthForm from "components/templates/AuthForm";
import { useAppSelector } from "redux/hooks";
import SelectionForOtpForm from "./SelectionForOtpForm";
import AuthService from "services/auth.service";
import { useEffect } from "react";

export default function ForgotPassword() {
	const form = "LoginForm";

	const val= useAppSelector(
			(state) => state.form?.[form]?.values
	);
	const user = useAppSelector((state) => state.auth.user);
const navigate = useNavigate()

useEffect(()=>{
if (!val?.email && !val?.phone?.value) navigate('/forgot-password')
},[navigate, val?.email, val?.phone?.value])



	const handleSubmit = (values: any) => {

		let data = {...values}
		if(val.type === "email"){

			delete data.password
			delete data.phone
		} else if(val.type === 'phone'){
			delete data.password
			delete data.email
			if (data.phone?.data) {
				data.phone = `${values.phone.value}`;
				data.country = values.phone.data.countryCode;
				data.country_code = `+${values.phone.data.dialCode}`;
			} else {
				data.country = "false";
				data.phone = values.phone.value.substring(1);
				data.country_code = user.country_code;
			}
		
		
		}


		AuthService.getOtp(data,navigate)


	};
		

	return (
		<AuthForm>
			<SelectionForOtpForm onSubmit={handleSubmit} />
		</AuthForm>
	);
}
