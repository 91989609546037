import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import "./SideBar.scss";

import logo from "assets/logo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import Button from "components/atoms/Button";
import { useAppSelector } from "redux/hooks";

import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import AuthService from "services/auth.service";
import { FaAngleDown } from "react-icons/fa";
import LanguageMenu from "components/atoms/BasicMenu/LanguageMenu";

export default function SideBar({
  state,
  toggleDrawer,
  englishLang,
  arabicLang,
}: any) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("translation");

  const user = useAppSelector((state) => state.auth.user);

  const list = (state: any) => (
    <Box sx={{ width: "100%" }} role="presentation" onKeyDown={toggleDrawer}>
      <div className="sidebar__container">
        <div className="header">
          <img src={logo} alt="" />
          <CloseIcon onClick={toggleDrawer} className="sideCross" />
        </div>
        <div className="side__links">
          <NavLink
            onClick={toggleDrawer}
            to={
              i18n.language === "en"
                ? "https://iyelo.com/en"
                : "https://iyelo.com/ar"
            }
          >
            {t("backToYelo")}
          </NavLink>
          <NavLink onClick={toggleDrawer} to="/">
            {t("services")}
          </NavLink>
          <NavLink onClick={toggleDrawer} to="/business">
            {t("business")}
          </NavLink>
          <NavLink onClick={toggleDrawer} to="/unforgettable-moments">
            {t("unforgettable_moments")}
          </NavLink>
          <NavLink onClick={toggleDrawer} to="/first-class">
            {t("about")}
          </NavLink>

          {user && (
            <>
              <NavLink onClick={toggleDrawer} to="/profile">
                {t("profile")}
              </NavLink>
              <NavLink onClick={toggleDrawer} to="/bookings">
                {t("bookings")}
              </NavLink>
            </>
          )}
          <LanguageMenu
            list={[
              {
                text: "EN",
                onClick: englishLang,
              },
              {
                text: "عربي",
                onClick: arabicLang,
              },
            ]}
            avatar={false}
            color="#520A76"
          >
            <Stack direction="row" alignItems="center" columnGap={1}>
              <span>{i18n.language === "en" ? "EN" : "عربي"}</span>
              <FaAngleDown />
            </Stack>
          </LanguageMenu>
        </div>

        {
          <Stack style={{ padding: "30px 10px" }} spacing={1}>
            {!user ? (
              <>
                <Button
                  onClick={() => {
                    navigate("/login");
                    toggleDrawer();
                  }}
                  variant="outlined"
                  color="info"
                >
                  {t("login")}
                </Button>
                <Button
                  onClick={() => {
                    navigate("/signup");
                    toggleDrawer();
                  }}
                  variant="contained"
                  color="info"
                >
                  {t("signup")}
                </Button>
              </>
            ) : (
              <Button
                fullWidth
                style={{ boxShadow: "none" }}
                variant="contained"
                onClick={() => {
                  AuthService.logout();
                  toggleDrawer();
                }}
                color="info"
              >
                {t("logout")}
              </Button>
            )}
          </Stack>
        }
      </div>
    </Box>
  );

  return (
    <div>
      <>
        <Drawer anchor="top" open={state} onClose={toggleDrawer}>
          {list(state)}
        </Drawer>
      </>
    </div>
  );
}
