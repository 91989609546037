import { config } from "config";
import io from "socket.io-client";
import { log } from "../utils/logger.util";

let socket: any;

const SocketService = {
	connect: ({ _id, title, first_name, last_name }: any) => {
		let data = JSON.stringify({
			id: _id,
			role: "user",
			name: `${title} ${first_name} ${last_name}`,
		});

		socket = io(config.SOCKET_URL, { query: { data } });

		log("socket init");

		socket.on("connect", () => {
			log("socket connected", socket.id);
		});

		socket.on("disconnect", (reason: any) => {
			log("socket disconnected", reason);
		});

		socket.on("connect_error", (e: any) => {
			log("socket connect_error", e.message);
			// socket.connect();
		});

		socket.onAny((event: any, ...args: any) => {
			log("socket event", { event, args });
		});
	},

	disconnect: () => {
		if (!socket) return;

		socket.disconnect();
	},
};

export default SocketService;
