import { Container, Stack } from "@mui/material";
import "./NavBar.scss";

import logo from "assets/logo.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Button from "components/atoms/Button";
import AuthService from "services/auth.service";
import { useAppSelector } from "redux/hooks";
import { useTranslation } from "react-i18next";
import { FaAngleDown } from "react-icons/fa";
import BasicMenu from "components/atoms/BasicMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { useCallback, useEffect, useState } from "react";
import SideBar from "../SideBar/SideBar";

const NavBar = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("translation");
  const user = useAppSelector((state) => state.auth.user);
  const { pathname } = useLocation();

  console.log("i18nnnn", i18n)
  

  const [state, setState] = useState(false);

  const toggleDrawer = () => {
    setState(!state);
  };

  const changeLanguage = useCallback((lng: any)=>{
    
      i18n.changeLanguage(lng);
  
  },[i18n])

  const arabicLang = useCallback(() => {
    localStorage.setItem("lang", 'ar')

    changeLanguage('ar');

    document.body.style.direction = "rtl";
    document.body.style.fontFamily = "arabic";
  },[changeLanguage])
  const englishLang = useCallback(
    () => {
      localStorage.setItem("lang", 'en')
  
      changeLanguage('en');
      document.body.style.direction = "ltr";
      document.body.style.fontFamily = "inter";

    },[changeLanguage]
  )

  useEffect(()=>{
    
    if(i18n.language ==='ar') {
      arabicLang()
    }else{
      englishLang()
    }
    
    

  },[arabicLang, englishLang, i18n.language])

  const links = [
    { path: 
      i18n.language === "en"?
      "https://iyelo.com/en":
      "https://iyelo.com/ar"
      
      , text: t("backToYelo") },
    { path: "/", text: t("services") },
    { path: "/business", text: t("business") },
    // { path: "/unforgettable-moments", text: t("unforgettable_moments") },
    
    { path: "/about#service", text: t("about") },
  ];
  if (pathname === "/payment-form") return <></>;
  return (
    <div className="navbar__container">
      <Container maxWidth="lg">
        <div className="navbar">
          <Stack
            className="cursor"
            onClick={() => navigate("/")}
            direction="row"
          >
            <img src={logo} alt="" />
          </Stack>
          <div className="routes">
            {links.map(({ path, text }, i) => (
              <NavLink key={i} to={path} className="links">
                {text}
              </NavLink>
            ))}
            <BasicMenu
              list={[
                {
                  text: "EN",
                  onClick: englishLang,
                },
                {
                  text: "عربي",
                  onClick: arabicLang,
                },
              ]}
              avatar={false}
              color="#ffffff"
            >
              <Stack direction="row" alignItems="center" columnGap={1}>
                <span>{i18n.language === "en" ? "EN" : "عربي"}</span>
                <FaAngleDown />
              </Stack>
            </BasicMenu>
          </div>
          <div className="hide__login">
            {user ? (
              <Stack direction="row" spacing={2}>
                <BasicMenu
                  list={[
                    {
                      text: t("profile"),
                      onClick: () => navigate("/profile"),
                    },
                    {
                      text: t("bookings"),
                      onClick: () => navigate("/bookings"),
                    },
                    // {
                    //   text: "Wallet",
                    //   onClick: () => navigate("/wallet"),
                    // },
                    {
                      text: t("logout"),
                      onClick: () => AuthService.logout(),
                    },
                  ]}
                  avatar={true}
                >
                  {user.first_name.charAt() + user.last_name.charAt()}
                </BasicMenu>
              </Stack>
            ) : (
              <Stack direction="row" spacing={2}>
                <Button
                  onClick={() => navigate("/login")}
                  variant="text"
                  color="primary"
                >
                  {t("login")}
                </Button>
                <Button
                  onClick={() => navigate("/signup")}
                  variant="contained"
                  color="primary"
                >
                  {t("signup")}
                </Button>
              </Stack>
            )}
          </div>
        </div>
      </Container>

      <MenuIcon onClick={toggleDrawer} 
      
      className={i18n.language ==="ar"?"burger__icons ar_burger":"burger__icons"}
       />
      <SideBar state={state} toggleDrawer={toggleDrawer} englishLang={englishLang} arabicLang={arabicLang}  />
    </div>
  );
};

export default NavBar;
