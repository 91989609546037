import { Helmet } from "react-helmet";

const Snap = () => {
  return (
    <Helmet>
      <script  id="snap-pixel-script">
        {`
        (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function() {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)}; a.queue=[];var s='script';r=t.createElement(s);r.async=!0; r.src=n;var u=t.getElementsByTagName(s)[0]; u.parentNode.insertBefore(r,u);})(window,document, 'https://sc-static.net/scevent.min.js'); snaptr('init', '4be5aeb1-57cd-4b30-a26a-774d9c456c7d', { 'user_email': '__INSERT_USER_EMAIL__' }); snaptr('track', 'PAGE_VIEW'); 
        `}
      </script>
    </Helmet>
  );
};

export default Snap;
