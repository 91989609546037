import "./ClassPage.scss";
// Assets
import classImage from "assets/classes/business-banner.png";
import model1 from "assets/classes/business-middle-1.png";
import model2 from "assets/classes/business-middle-2.png";
import key1 from "assets/classes/business-feature-1.webp";
import key2 from "assets/classes/business-feature-2.png";
import key3 from "assets/classes/business-feature-3.png";
import key4 from "assets/classes/business-feature-4.webp";
import liked1 from "assets/liked1.webp";
import liked4 from "assets/liked4.webp";
import btnNext from "assets/btnNext.svg";
import liked2 from "assets/liked2.webp";
// MUI
import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";



const BusinessClass = () => {
  const navigate = useNavigate()
  const {t} = useTranslation("translation")
  const {i18n} = useTranslation();

  const arr = [
    {
      img: liked1,
      name: t("firstClassCar"),

      link: "/first-class",
    },
    {
      img: liked4,
      name: t("businessVan"),

      link: "/van-class",
    },
    {
      img: liked2,
      name: t("luxurySuv"),

      link: "/luxury-class",
    },
  ];

  return (
    <div className="class__container">
      <div className="class__header">
        <img src={classImage} alt="" />
      </div>
      <Container maxWidth="lg">
        <div className="class__name">
          <div
                    className={i18n.language ==="en"?"first":"first changeArabic"}

          
          >{t("business")}</div>
          <div 
           className={i18n.language ==="en"?"second":"second changeArabic"}
          >
          {t("statusSymbol")}
          </div>
        </div>
        <div className="models">
          <div className="model1">
            <img src={model1} alt="" />
            <div className="mode__heading">{t("eClass")}</div>
            <div className="modal__para">
            {t("eleganceComfort")}
            </div>
          </div>
          <div className="model1">
            <img src={model2} alt="" />
            <div className="mode__heading">{t("5series")}</div>
            <div className="modal__para">
            {t("perfectBalance")}
            </div>
          </div>
        </div>
        <div className="keyFeature">
          <div className="header">{t("keyFeatures")}</div>
          <div className="keyBottom">
            <div className="left">
              <img src={key1} alt="" className="upper" />
              <img src={key2} alt="" className="bottom" />
            </div>
            <div className="right">
              <img src={key3} alt="" className="upper" />
              <img src={key4} alt="" className="bottom" />
            </div>
          </div>
        </div>
        <div className="liked__cars">
          <div className="heading">
          {t("mightLike")}
          </div>
          <Grid container className="cars">
            {arr.map((data: any, i: number) => {
              return (
                <Grid item xs={12} sm={6} md={3} lg={3} key={i}>
                  <div className="car1">
                    <img src={data.img} alt="" className="car__image" />
                    <div className="card__heading">{data.name}</div>
                    <div
                      className="more__btn cursor"
                      onClick={() => navigate(data.link)}
                    >
                      <div className="btn_text">{t("moreInfo")}</div>
                      <img 
                                  className={i18n.language ==="ar"?"rotate":""}

                      src={btnNext} alt="" />
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default BusinessClass;
