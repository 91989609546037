import { Suspense } from "react";
import PrivateRoute from "./PrivateRoute";
import { private_routes, public_routes } from "routes";
import { Navigate, Route, Routes } from "react-router-dom";
import CircleLoader from "components/atoms/CircleLoader";
// import FullScreenLoader from "components/atoms/FullScreenLoader";

export default function AppRoutes() {
  return (
    <div style={{ minHeight: "100vh", position: "relative" }}>

<Suspense fallback={<CircleLoader />}>
      <Routes>
        {public_routes.map(({ path, element }, i) => (
          <Route key={i} path={path} element={element} />
        ))}

        {private_routes.map(({ path, element }, i) => (
          <Route
            key={i}
            path={path}
            element={<PrivateRoute>{element}</PrivateRoute>}
          />
        ))}

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
    </div>

  );
}
