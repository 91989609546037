import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import authReducer from "./slices/auth";
import classReducer from "./slices/class";
import modalReducer from "./slices/modal";
import loaderReducer from "./slices/loader";
import bookingReducer from "./slices/booking";
import paymentReducer from "./slices/payment";
import { mapReducer } from "./slices/map/mapSlice";

const appReducer = combineReducers({
  form: formReducer,

  auth: authReducer,
  modal: modalReducer,
  classes: classReducer,
  loading: loaderReducer,
  payment: paymentReducer,
  booking: bookingReducer,
  map: mapReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") {
    const form = state.form;
    const classes = state.classes;

    state = { form, classes };
  }

  return appReducer(state, action);
};

export default rootReducer;
