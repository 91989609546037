import AOS from "aos";
import ErrorService from "services/error.service";

const InitializationService = {
	init: () => {
		ErrorService.init();
		AOS.init({ once: true, easing: "ease", duration: 700 });
	},
};

export default InitializationService;
