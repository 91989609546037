import { AuthState } from ".";
import LocalStorage from "utils/localstorage.util";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const user = LocalStorage.getItem("user");
const initialState: AuthState = {
	user,
	loading: false,
	showSignup: false,
	otpInfo:{}
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		logout: () => {},
		setUser: (state, action) => {
			state.user = action.payload;
			LocalStorage.setItem("user", state.user);
		},
		setUserTripRating: (state, action: PayloadAction<boolean>) => {
			if (state.user && action.payload) {
				state.user.trip_rating = { is_rating: false };
				LocalStorage.setItem("user", state.user);
			}
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setShowSignup: (state, action: PayloadAction<boolean>) => {
			state.showSignup = action.payload;
		},
		setOtpInfo :(state, action)=>{
			state.otpInfo = action.payload
		}
	},
});

const authReducer = authSlice.reducer;

export const authActions = authSlice.actions;
export default authReducer;
