export const config = {
  NODE_ENV: process.env.NODE_ENV,
  APP_ENV: process.env.REACT_APP_ENV,

  API_URL: process.env.REACT_APP_API_URL,
  // API_URL: process.env.REACT_APP_LOCAL_API_URL, // local
  APP_URL: `${process.env.REACT_APP_URL}`,
  APP_URL_APPLE_PAY: `${process.env.REACT_APP_URL_APPLE_PAY}`,
  SOCKET_URL: `${process.env.REACT_APP_SOCKET_URL}`,
  GUEST_API_URL: process.env.REACT_APP_GUEST_API_URL,
  LOCAL_API_URL: process.env.REACT_APP_LOCAL_API_URL,
  GUEST_LOCAL_API_URL: process.env.REACT_APP_GUEST_LOCAL_API_URL,

  STRIPE_KEY: `${process.env.REACT_APP_STRIPE_KEY}`,
  BRAINTREE_KEY: `${process.env.REACT_APP_BRAINTREE_KEY}`,
  LIVE_COMPANY: `${process.env.REACT_APP_COMPANY_URL}`,

  SENTRY_PUBLIC_KEY: process.env.REACT_APP_SENTRY_PUBLIC_KEY,
  SENTRY_RELEASE_KEY: process.env.REACT_APP_SENTRY_RELEASE_KEY,

  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,

  MAX_KM_PER_HOUR: Number(process.env.REACT_APP_MAX_KM_PER_HOUR) || 0,
};
