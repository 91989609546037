import { useEffect } from "react";
import { PersistReduxFormProps } from ".";
import LocalStorage from "utils/localstorage.util";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export default function PersistReduxForm({ form }: PersistReduxFormProps) {
	const dispatch = useAppDispatch();
	const formValues = useAppSelector((state) => state.form?.[form]?.values);

	useEffect(() => {
		LocalStorage.setFormValues(form, dispatch);
	}, [dispatch, form]);

	useEffect(() => {
		if (!formValues) return;
		LocalStorage.setItem(form, formValues);
	}, [form, formValues]);

	return null;
}
