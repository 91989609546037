import React from "react";
import FBIG from "./FBIG";
import Snap from "./Snap";
import Tiktok from "./Tiktok";
import Twitter from "./Twitter";
import TwitterAR from "./TwitterAR";

const SocialPixels = () => {
  return (
    <>
      <FBIG />
      <Snap />
      <Tiktok />
      <Twitter />
      <TwitterAR />
    </>
  );
};

export default SocialPixels;
