import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import BookingService from "services/booking.service";

import "./loader.css";

export default function PaymentHandler() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [showError, errorHandler] = useState(false);
  const processPayment = async (id: string) => {
    const [sucess, error] = await BookingService.processPayment(id);


    if (!error) {
      const div: any = document.getElementById("payment_form_div");
      div.innerHTML = sucess.form;
      const button: any = document.getElementById("pay_btn_form");
      button.click();
    }
  };
  useEffect(() => {
    if (params.get("error") == "payment_error") {
      errorHandler(true);
      return;
    }
    const id: any = params.get("order");
    processPayment(id);

    // const form = `
    //   <form
    //   id="payfort_payment"
    //   action="https://sbcheckout.payfort.com/FortAPI/paymentPage"
    //   method="POST"
    // >
    //   <input
    //     type="hidden"
    //     id="merchant_identifier"
    //     name="merchant_identifier"
    //     value='${params.get("identifier")}'
    //   />

    //   <input
    //     type="hidden"
    //     id="access_code"
    //     name="access_code"
    //     value='${params.get("code")}'
    //   />
    //   <input
    //     type="hidden"
    //     id="merchant_reference"
    //     name="merchant_reference"
    //     value='${params.get("ref")}'
    //   />
    //   <input
    //     type="hidden"
    //     id="customer_email"
    //     name="customer_email"
    //     value='${params.get("amount")}'
    //   />

    //   <input
    //     type="hidden"
    //     id="return_url"
    //     name="return_url"
    //     value='${params.get("url")}'
    //   />
    //   <input type="hidden" id="language" name="language" value="en" />
    //   <input type="hidden" id="command" name="command" value="AUTHORIZATION" />
    //   <input type="hidden" id="amount" name="amount" value='${params.get(
    //     "sar"
    //   )}' />
    //   <input
    //     type="hidden"
    //     id="signature"
    //     name="signature"
    //     value='${params.get("signature")}'
    //   />
    //   <input type="hidden" id="currency" name="currency" value="SAR" />
    //   <button type="submit" id="pay_btn_form">Custom Page Integration</button>
    // </form>
    // `;

    // const div: any = document.getElementById("payment_form_div");

    // div.innerHTML = form;

    // const button: any = document.getElementById("pay_btn_form");

    // button.click();
  }, []);

  return (
    <div>
      <div id="loader">
        <h3>Processing...</h3>
        <div className="loader"></div>
        <div id="payment_form_div"></div>
      </div>

      {showError && <h1>Error Occurred</h1>}
    </div>
  );
}
