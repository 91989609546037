import "./AuthForm.css";
import auth from "assets/auth.png";
import authLogo from "assets/authLogo.png";
import ArAuthLogo from "assets/ArAuthLogo.png";
import yeloShort from "assets/yeloShort.svg";
import hand from "assets/hand.svg";
import { Container, Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CircleLoader from "components/atoms/CircleLoader";
import Button from "components/atoms/Button";
import { authActions } from "redux/slices/auth";
import { useTranslation } from "react-i18next";

export default function AuthForm({ children }: any) {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation("translation");

  let email = localStorage.getItem("email");
  const loading = useAppSelector((state) => state.auth.loading);
  const showSignup = useAppSelector((state) => state.auth.showSignup);

  const searchStyle ={
    marginTop:"6rem",
    border:"1px solid grey",
    borderRadius:"30px"
  }
  const loginStyle ={
    
  }

  return (
    <div
      className={`${
        pathname === "/search-form"
          ? "search-login-container"
          : "login-container"
      }`}
    >
      <Container maxWidth="lg">
        <Grid
          container
          //  direction="row"
          //  justifyContent="center"
          //  alignItems="center"
          style={pathname==="/search-form"?searchStyle:loginStyle}
          
          minHeight="520px"
        >
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <div className="auth_left_image">
              <img
                src={auth}
                alt=""
                className={
                  i18n.language === "ar"
                    ? "authImage ar__authImage"
                    : "authImage"
                }
              />
              <h3
                className={
                  i18n.language === "ar"
                    ? "travelInStyle changeArabic"
                    : "travelInStyle"
                }
              >
                {t("travelInStyle")}
              </h3>
              {i18n.language === "ar" ? (
                <p className="yeloFamily changeArabic">
                  أصبحت الليموزين الآن جزءًا من عائلة &nbsp;
                  <img src={yeloShort} alt="" />
                </p>
              ) : (
                <p className="yeloFamily">
                  Limousine is now part of the <img src={yeloShort} alt="" />{" "}
                  family.
                </p>
              )}
              <div className="border"></div>
              <img
                src={i18n.language === "ar" ? ArAuthLogo : authLogo}
                alt=""
                className="authLogo"
              />
            </div>
          </Grid>
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <div
              className={
                i18n.language === "ar"
                  ? "wrap-login ar__wrap-login"
                  : "wrap-login"
              }
            >
              {loading && <CircleLoader />}

              {/* <span className="login-form-logo">
            <img alt="logo" src={Logo} loading="lazy" />
          </span> */}

              <span className="login-form-title">
                {pathname === "/login" ? (
                  <>
                    {t("hello")}
                    <img
                      style={{ height: "25px", marginLeft: "5px" }}
                      src={hand}
                      alt=""
                    />
                    {/* {t("welcomeBackTo")} */}
                    <br />
                    {t("gladToHaveYOu")}
                    <br />
                    <b> {t("yelo")}!</b>
                  </>
                ) : pathname === "/search-form" ? (
                  <b> {showSignup ? t("signup") : t("login")}</b>
                ) : pathname === "/signup" ? (
                  <>
                    {t("welcomeTo")}
                    <b> {t("yelo")}!</b>
                  </>
                ) : pathname === "/forgot-password" ? (
                  <b> {t("forgotPassword")}!</b>
                ) : pathname === "/select-otp-option" ? (
                  <b> {t("varificationCode")}!</b>
                ) : pathname === "/password-otp" ? (
                  <b> {t("varificationCode")}</b>
                ) : pathname === "/reset-password" ? (
                  <b> {t("resetPass")}!</b>
                ) : null}
              </span>
              <span className="login-form-slogan">
                {
                  // pathname === "/login" ||
                  // (pathname === "/search-form" && !showSignup) ? (
                  //   <>
                  //     {t("don'tHaveAccount")}
                  //     {pathname === "/search-form" ? (
                  //       <Button
                  //         onClick={() =>
                  //           dispatch(authActions.setShowSignup(true))
                  //         }
                  //       >
                  //         {t("signup")}
                  //       </Button>
                  //     ) : (
                  //       <Link to="/signup"> {t("signup")}</Link>
                  //     )}
                  //   </>
                  // )

                  // : pathname === "/signup" ||
                  //   (pathname === "/search-form" && showSignup) ? (
                  //   <>
                  //     {t("alreadyHaveAccount")}?
                  //     {pathname === "/search-form" ? (
                  //       <Button
                  //         onClick={() =>
                  //           dispatch(authActions.setShowSignup(false))
                  //         }
                  //       >
                  //         {t("login")}
                  //       </Button>
                  //     ) : (
                  //       <Link to="/login"> {t("login")}</Link>
                  //     )}
                  //   </>
                  // )
                  //  :
                  pathname === "/forgot-password" ? (
                    <>{t("getVarificationCode")}</>
                  ) : pathname === "/select-otp-option" ? (
                    <>{t("selectOneToGet")}</>
                  ) : pathname === "/password-otp" ? (
                    <>
                      {" "}
                      {t("sentVarificationCode")}
                      {email}
                    </>
                  ) : pathname === "/reset-password" ? (
                    <> {t("newPass")}</>
                  ) : null
                }
              </span>
              <div className="login-form-inner">{children}</div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
