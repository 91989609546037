import { Container } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import "./Footer.scss";

import award from "assets/award.svg";
import youtube from "assets/youtube.svg";
import Linkedin from "assets/Linkedin.svg";
import Twitter from "assets/Twitter.svg";
import fb from "assets/fb.svg";

import appStore from "assets/appStore.png";
import playStore from "assets/playStore.png";

import { Link, useLocation } from "react-router-dom";
import Text from "components/atoms/Text/Text";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation("translation");

  const changeLanguage = useCallback(
    (lng: any) => {
      i18n.changeLanguage(lng);
    },
    [i18n]
  );

  const arabicLang = useCallback(() => {
    localStorage.setItem("lang", "ar");

    changeLanguage("ar");

    document.body.style.direction = "rtl";
    document.body.style.fontFamily = "arabic";
  }, [changeLanguage]);
  const englishLang = useCallback(() => {
    localStorage.setItem("lang", "en");

    changeLanguage("en");
    document.body.style.direction = "ltr";
    document.body.style.fontFamily = "inter";
  }, [changeLanguage]);

  useEffect(() => {
    if (i18n.language === "ar") {
      arabicLang();
    } else {
      englishLang();
    }
  }, [arabicLang, englishLang, i18n.language]);
  if (
    // !user ||
    pathname === "/login" ||
    pathname === "/signup" ||
    pathname === "/password-otp" ||
    pathname === "/select-otp-option" ||
    pathname === "/reset-password" ||
    pathname === "/payment-form" ||
    pathname === "/forgot-password"
  )
    return <></>;
  return (
    <div className="footer__container">
      <Container maxWidth="lg">
        <div className="bestCar__Rental">
          <div className="left">
            <div className="award">
              <img src={award} alt="" className="award__img" />
            </div>
            <div className="awad__text">
              <Text text={t("bestAward")} size="16" weight="700" height="19" />
            </div>
          </div>
          <div className="right">
            <div className="first">
              <Text
                text={t("footerCompany")}
                size="16"
                weight="700"
                height="19"
              />
              <div className="links">
                <Link to="https://iyelo.com/en/about">{t("aboutUs")}</Link>
                <Link to="/contactUs">{t("contactUs")}</Link>
                <Link to="https://iyelo.com/en/careers">{t("careers")}</Link>
                <Link to="/business">{t("footerBusiness")}</Link>
              </div>
            </div>
            <div className="first">
              <Text text={t("rent")} size="16" weight="700" height="19" />
              <div className="links">
                <Link to="/first-class">{t("fleet")}</Link>
              </div>
            </div>
            <div className="first">
              <Text
                text={t("usefulLinks")}
                size="16"
                weight="700"
                height="19"
              />
              <div className="links">
                <Link to="https://iyelo.com/en/privacy-policy">
                  {t("privacy")}
                </Link>
                <Link to="https://iyelo.com/en/terms-of-use">{t("terms")}</Link>
                <Link to="https://iyelo.com/en/cookie-policy">
                  {t("cookies")}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="subscribe__offer">
          {/* <div className="subscribe">
              <Text
                text={t("subscribe")}
                size="16"
                weight="700"
                height="19"
              />
              <Text
                text={t("saveOurLatestPrice")}
                size="14"
                weight="400"
                height="17"
              />

              <div 
    
              className={i18n.language ==="en"?"email":"email ar__email"}

              >
                <img src={email} alt="" />
                <input
                // @ts-ignore
                  placeholder={t("email")}
                  type="text"
                  className="email__input"
                />
              </div>
            </div> */}
          <div className="subscribe">
            <Text text={t("yeloApp")} size="16" weight="700" height="19" />
            <Text
              text={t("bookAndManage")}
              size="14"
              weight="400"
              height="17"
            />
            <div className="playstore">
              <Link
                to="https://apps.apple.com/us/app/yelo-limousine/6446365805"
                target="_blank"
              >
                <img src={appStore} alt="" />
              </Link>
              <Link
                to="https://play.google.com/store/apps/details?id=com.wefaq.limousine"
                target="_blank"
              >
                <img src={playStore} alt="" />
              </Link>
            </div>
          </div>
          <div className="subscribe">
            <Text
              text={t("connectWithUs")}
              size="16"
              weight="700"
              height="19"
            />
            <Text
              text={t("shareExperience")}
              size="14"
              weight="400"
              height="17"
            />
            <div className="social__links">
              <Link to="https://www.facebook.com/YeloDrive/">
                <img src={fb} alt="" />
              </Link>
              <Link to="https://twitter.com/YeloDrive">
                <img src={Twitter} alt="" />
              </Link>
              <Link
                to="https://www.linkedin.com/company/yelodrive/

"
              >
                <img src={Linkedin} alt="" />
              </Link>
              <Link to="https://www.youtube.com/channel/UC-63qjJCB4LOw_4j8Nk5dmg">
                <img src={youtube} alt="" />
              </Link>
            </div>
          </div>
        </div>

        <div className="rights">
          <Text text={t("allRights")} size="14" weight="400" height="17" />
          {/* <BasicMenu
            list={[
              {
                text: "EN",
                onClick: englishLang,
              },
              {
                text: "عربي",
                onClick: arabicLang,
              },
            ]}
            avatar={false}
            color="black"
          >
            <Stack direction="row" alignItems="center" columnGap={1}>
              <span>{i18n.language === "en" ? "EN" : "عربي"}</span>
              <FaAngleDown />
            </Stack>
          </BasicMenu> */}
        </div>
      </Container>
    </div>
  );
};

export default Footer;
