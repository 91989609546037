
import Button from "components/atoms/Button";
import PersistReduxForm from "components/organisms/PersistReduxForm";
import { Grid } from "@mui/material";
import RadioGroupRedux from "components/molecules/RadioGroupRedux";
import { Field, reduxForm } from "redux-form";
import { useAppSelector } from "redux/hooks";
import { useTranslation } from "react-i18next";

function SelectionForOtpForm({ handleSubmit, form }: any) {
  const info = useAppSelector((state)=>state.auth.otpInfo)
  const { t } = useTranslation("translation");


 
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid style={{ marginBottom: "10px" }} item lg={6}>
          <Field
            name={"sendMedia"}
            label={""}
            component={RadioGroupRedux}
            RadioGroupProps={{
              values: [
                { value: "email", label: info.email },
                { value: "phone", label: info.phone },
              ],
            }}
          />
        </Grid>

   
 
        <Button
          sx={{ 
            height: "50px",
            width: "100%",
            marginTop:"15px"

           }}
          color="info"
          variant="contained"
          type="submit"
        >
          {t("submit")}
        </Button>
      </form>
      <PersistReduxForm form={form} />
    </div>
  );
}

export default reduxForm({
  form: "LoginForm",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(SelectionForOtpForm);
