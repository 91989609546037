import BusinessClass from "pages/ClassPage/BusinessClass";
// import FirstClass from "pages/ClassPage/FirstClass";
import LuxuryClass from "pages/ClassPage/LuxuryClass";
import VanClass from "pages/ClassPage/VanClass";
import PaymentHandler from "pages/PaymentHandler/PaymentHandler";
import SelectionForOtp from "pages/SelectionForOtp";
import { lazy } from "react";

// const Home = lazy(() => import("pages/Home"));

// const Faqs = lazy(() => import("pages/Faqs"));
// const AboutUs = lazy(() => import("pages/AboutUs"));
const ContactUs = lazy(() => import("pages/ContactUs"));

// const Careers = lazy(() => import("pages/Careers"));
// const Partner = lazy(() => import("pages/Partner"));
// const Company = lazy(() => import("pages/Company"));
// const EventsPage = lazy(() => import("pages/EventsPage"));
// const CareerApply = lazy(() => import("pages/CareerApply"));
// const ServicesPage = lazy(() => import("pages/ServicesPage"));
// const ServiceRequest = lazy(() => import("pages/ServiceRequest"));
// const PartnerRegister = lazy(() => import("pages/PartnerRegister"));

// const Imprint = lazy(() => import("pages/Imprint"));
// const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));
// const CookiesPolicy = lazy(() => import("pages/CookiesPolicy"));
// const TermsConditions = lazy(() => import("pages/TermsConditions"));
// const ConditionsTransport = lazy(() => import("pages/ConditionsTransport"));

const LoginPage = lazy(() => import("pages/LoginPage"));
const SignupPage = lazy(() => import("pages/SignupPage"));
const SearchForm = lazy(() => import("pages/SearchForm"));
const PasswordOTP = lazy(() => import("pages/PasswordOTP"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));

const Wallet = lazy(() => import("pages/Wallet"));
const Profile = lazy(() => import("pages/Profile"));
const Bookings = lazy(() => import("pages/Bookings"));
const Business = lazy(() => import("pages/Business"));
const Services = lazy(() => import("pages/Services"));
const Landing = lazy(() => import("pages/Landing"));
const PaymentForm = lazy(() => import("pages/PaymentForm"));
const AccountDelete = lazy(() => import("pages/AccountDelete"));
const DeleteUserAccount = lazy(() => import("pages/Delete-user-account"));
const DeleteDriverAccount = lazy(() => import("pages/Delete-driver-account"));

export { default } from "./AppRoutes";

export interface IRoute {
  path: string;
  element: JSX.Element;
}

export const public_routes: IRoute[] = [
  { path: "/business", element: <Business /> },
  { path: "/", element: <Services /> },
  { path: "/about", element: <Services /> },
  { path: "/unforgettable-moments", element: <Landing /> },
  // { path: "/first-class", element: <FirstClass /> },
  { path: "/van-class", element: <VanClass /> },
  { path: "/business-class", element: <BusinessClass /> },
  { path: "/luxury-class", element: <LuxuryClass /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/signup", element: <SignupPage /> },
  { path: "/password-otp", element: <PasswordOTP /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/select-otp-option", element: <SelectionForOtp /> },
  { path: "/delete-user-account", element: <DeleteUserAccount /> },
  { path: "/delete-driver-account", element: <DeleteDriverAccount /> },

  { path: "/search-form", element: <SearchForm /> },

  {
    path: "/payment",
    element: <PaymentHandler />,
  },
  { path: "/payment-form", element: <PaymentForm /> },
  { path: "/account/delete", element: <AccountDelete /> },
  { path: "/contactUs", element: <ContactUs /> },
];

export const private_routes: IRoute[] = [
  { path: "/wallet", element: <Wallet /> },
  { path: "/profile", element: <Profile /> },
  { path: "/bookings", element: <Bookings /> },
];
