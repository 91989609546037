import "./App.scss";

import { store } from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import AppRoutes from "routes/AppRoutes";
import Toaster from "components/atoms/Toaster";
import Footer from "components/templates/Footer";
import AppModal from "components/templates/AppModal";
import ScrollTop from "components/templates/ScrollTop";
import ErrorBoundary from "components/atoms/ErrorBoundary";
import RegisterAppDispatch from "components/atoms/RegisterAppDispatch";

import createCache from "@emotion/cache";
import { SnackbarProvider } from "notistack";
import { CacheProvider } from "@emotion/react";
import { LocalizationProvider } from "@mui/lab";
import dateAdapter from "@mui/lab/AdapterDateFns";
import { ThemeProvider } from "@mui/material/styles";
import NavBar from "components/templates/NavBar";
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { useTranslation } from "react-i18next";
import { createTheme } from "@mui/material";
import SocialPixels from "socialPixels";

declare module "@mui/material/styles" {
	interface Theme {
		status: {
			success: object;
			warning: object;
		};
		customColor: {
			secondary: object;
		};
	}
	interface ThemeOptions {
		status?: {
			success?: object;
			warning?: object;
		};
		customColor: {
			secondary: object;
		};
	}
}


// const stripePromise = loadStripe(config.STRIPE_KEY);
const cache = createCache({ key: "css", prepend: true });

const cacheRtl = createCache({
	key: 'muirtl',
	//@ts-ignore
	stylisPlugins: [prefixer, rtlPlugin],
 });

export default function App() {
	const {i18n} = useTranslation();






	const theme = createTheme({
		status: {
			success: {
				color: "rgba(84, 214, 44, 0.16)",
				textContrast: "rgb(34, 154, 22)",
			},
			warning: {
				color: "rgba(255, 193, 7, 0.16)",
				textContrast: "rgb(183, 129, 3)",
			},
		},
		customColor: {
			secondary: {
				color: "rgba(0, 0, 0, 0.53)",
			},
		},
		palette: {
			primary: {
				light: "#FFCD00",
				main: "#FFCD00",
				dark: "#FFCD00",
				contrastText: "#520A76",
			},
			secondary: {
				light: "#FFFFFF",
				main: "#FFFFFF",
				dark: "#FFFFFF",
				contrastText: "#520A76;",
			},
			success: {
				main: "rgb(34, 154, 22)",
				light: "rgba(84, 214, 44, 0.16)",
				dark: "#1b5e20",
				// contrastText: "rgb(34, 154, 22)",
			},
			warning: {
				main: "rgb(183, 129, 3)",
				light: "rgba(255, 193, 7, 0.16)",
				dark: "#e65100",
				// contrastText: "rgb(183, 129, 3)"
			},
			error: {
				main: "rgb(183, 33, 54)",
				light: "rgba(255, 72, 66, 0.16)",
				dark: "#c62828",
				// contrastText: "rgb(183, 33, 54)",
			},
			info: {
				main: "#520A76",
				light: "#520A76",
				dark: "#520A76",
				contrastText: "#FFFFFF",
			},
		},
		typography: {
			fontFamily: i18n.language ==="ar"?'arabic':'inter',
		},
	});

	return (
		<ErrorBoundary>
			<SocialPixels/>
			<SnackbarProvider
				maxSnack={5}
				hideIconVariant
				preventDuplicate
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				iconVariant={{
					success: "✅",
					error: "✖️",
					warning: "⚠️",
					info: "ℹ️",
				}}
			>
				<Toaster />
				<ErrorBoundary>
					{/* <Elements stripe={stripePromise}> */}
						<CacheProvider value={i18n.language ==="ar"?cacheRtl:cache}>
							
							<ThemeProvider theme={theme}>
								<LocalizationProvider dateAdapter={dateAdapter}>
									{/* <CssBaseline /> */}
									{/* <ScopedCssBaseline> */}
									<Provider store={store}>
										<RegisterAppDispatch />
										<BrowserRouter>
											<ErrorBoundary>
												<NavBar/>
											</ErrorBoundary>
											{/* <ErrorBoundary>
												<Cookies />
											</ErrorBoundary> */}
											<ErrorBoundary>
												<ScrollTop />
											</ErrorBoundary>
											<ErrorBoundary>
												<AppModal />
											</ErrorBoundary>
											<ErrorBoundary>
												<AppRoutes />
											</ErrorBoundary>
											<ErrorBoundary>
												<Footer />
											</ErrorBoundary>
										</BrowserRouter>
									</Provider>
									{/* </ScopedCssBaseline> */}
								</LocalizationProvider>
							</ThemeProvider>
						</CacheProvider>
					{/* </Elements> */}
				</ErrorBoundary>
			</SnackbarProvider>
		</ErrorBoundary>
	);
}
