import React from "react";

import "./index.css";
import App from "./App";

import "aos/dist/aos.css";

import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import ErrorBoundary from "components/atoms/ErrorBoundary";
import InitializationService from "services/initialization.service";
import { render } from "react-dom";

import "./locales/config";

InitializationService.init();

render(
  // <React.StrictMode>
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  // </React.StrictMode>,
  document.getElementById("root")
);
// render(
//   <React.StrictMode>
//     <ErrorBoundary>
//       <App />
//     </ErrorBoundary>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// if (config.NODE_ENV === "development")
serviceWorkerRegistration.unregister();
// else if (config.NODE_ENV === "production")
// 	serviceWorkerRegistration.register({
// 		onUpdate: (reg, message) => log("pwa onUpdate", reg.scope, message),
// 		onSuccess: (reg, message) => log("pwa onSuccess", reg.scope, message),
// });

reportWebVitals();
